import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { GameService } from '@gamelist/services/api/game.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  
    private userData: any = {};  // Store the user data after calling checkLoggedinUserData()

    constructor(public gameService: GameService) {
        // Initialize user data by calling checkLoggedinUserData() once
        this.userData = this.checkLoggedinUserData();
    }

    // Check user data from cookies
    checkLoggedinUserData() {
        // Split the document.cookie string by ";"
        let cookiesArray: string[] = document.cookie.split(";");
        let cookieObject: any = {};
        let cookieData: any = [];
        let userId: any = null;
        let subscription_level: number = 0;
        let isUserLoggedIn: boolean = false;
        let email: string = ''; // Assuming email is stored in cookies

        cookiesArray.forEach(cookie => {
            let cookieData = cookie.trim().split("=");
            
            cookieObject[cookieData[0]] = cookieData[1];
        });

        // Get cookie data
        cookieData = cookieObject;
        
        // Set isUserLoggedIn & user id
        if (cookieData.userid) {
            userId = cookieData.userid;
            email = cookieData.email || '';  // Assuming the email is stored in the cookie with key 'email'
            // Set subscription level
            if (environment.production) {
                subscription_level = 2;  // Static setting for production
            } else {
                subscription_level = cookieData.subscription_level;
            }
            isUserLoggedIn = true;
        }
        
        return { isUserLoggedIn, userId, email, subscription_level };
    }

    // Method to get the user email
    getUserEmail(): string {
        return this.userData.email || '';  // Returns the email from the stored user data
    }

    // Method to get the cookies string
    getCookie(): string {
        return document.cookie;  // Returns the full cookie string
    }

    private listSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    list$: Observable<string[]> = this.listSubject.asObservable();

    updateList(newList): void {
        this.listSubject.next(newList);
    }
}
