import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GameService } from '@gamelist/services/api/game.service';
import { Subject } from 'rxjs';
import { share, startWith, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-game-search',
  templateUrl: './game-search.component.html',
  styleUrls: ['./game-search.component.scss']
})
export class GameSearchComponent implements OnInit {

  showAdvanced: boolean = false;
  games$;
  filters$;
  selectedFilters = {
    status: ['slots_available', 'in_progress', 'archived'],
    turnLength: [86400, 300, 180],
    minSlots: null,
    maxSlots: null,
    minRank: null,
    maxRank: null,
    maps: [],
    players: [],
    objectives: [],
    teams: [],
    reserves: [],
    reinforcementAllowance: [],
    reinforcement: [],
    force: [],
    privacy: [],
    gamePlayers: [],
    isPlayer: false
  };
  selectedFilters$ = new Subject();

  @ViewChild('gameArea') gameArea: ElementRef;
  noSubscriptionMessage: string = '';

  constructor(
    private gameService: GameService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.showAdvanced = false;

    const subscription = this.route.snapshot.queryParams.subscription;
    if (subscription != undefined && subscription === 'no') {
      this.noSubscriptionMessage = "Creating Real-time Games has been temporarily disabled.<br> Please join one of the premade Real-time Games below.<br><br> Or feel free to request a game to be made by one of our admins.<br> <a class='text-blue-600' href='https://forum.majorcommand.com/threads/real-time-game-requests-here.9746/'>Real Time Game Request Thread</a>";
    }

    const gameType = this.route.snapshot.queryParams.type;
        if (gameType === 'realtime') {
        this.selectedFilters.turnLength = [300, 180]; // 5 min and 3 min for realtime games
    } else if (gameType === 'casual') {
        this.selectedFilters.turnLength = [86400]; // 24 hours for casual games
    }

    const onlyAvailable = this.route.snapshot.queryParams.available_slots === '1';
        this.selectedFilters.status = ['slots_available'];

    const showJoinedGames = this.route.snapshot.queryParams.showJoinedGames === 'true';
        this.selectedFilters.isPlayer = showJoinedGames;

    this.route.queryParams.subscribe(params => {
      // Load filters from query parameters (maps, status, players, etc.)
      if (params['status']) {
        this.selectedFilters.status = params['status'].split(',');
      }
      if (params['turnLength']) {
        this.selectedFilters.turnLength = params['turnLength'].split(',').map(Number);
      }
      if (params['minSlots']) {
        this.selectedFilters.minSlots = +params['minSlots'];
      }
      if (params['maxSlots']) {
        this.selectedFilters.maxSlots = +params['maxSlots'];
      }
      if (params['isPlayer']) {
        this.selectedFilters.isPlayer = params['isPlayer'] === 'true';
      }
      if (params['mapId']) { // original mapId
        this.selectedFilters.maps = params['mapId'].split(',');
      }
      if (params['map_key']) { // Changed from mapId
        this.selectedFilters.maps = params['map_key'].split(',').map(id => id.trim()).filter(id => id.length > 0);
      }      
      if (params['players']) {
        this.selectedFilters.players = params['players'].split(',');
      }
      if (params['objectives']) {
        this.selectedFilters.objectives = params['objectives'].split(',');
      }
      if (params['teams']) {
        this.selectedFilters.teams = params['teams'].split(',');
      }
      if (params['reserves']) {
        this.selectedFilters.reserves = params['reserves'].split(',');
      }
      if (params['reinforcementAllowance']) {
        this.selectedFilters.reinforcementAllowance = params['reinforcementAllowance'].split(',');
      }
      if (params['reinforcement']) {
        this.selectedFilters.reinforcement = params['reinforcement'].split(',');
      }
      if (params['force']) {
        this.selectedFilters.force = params['force'].split(',');
      }
      if (params['privacy']) {
        this.selectedFilters.privacy = params['privacy'].split(',');
      }
      if (params['gamePlayers']) {
        this.selectedFilters.gamePlayers = params['gamePlayers'].split(',');
      }

        // console.log('Parsed mapId:', this.selectedFilters.maps);
        // console.log('Parsed status:', this.selectedFilters.status);
        // console.log('Parsed turnLength:', this.selectedFilters.turnLength);
        // console.log('Parsed objectives:', this.selectedFilters.objectives);
        // console.log('Complete selectedFilters:', this.selectedFilters);

        // **Emit the updated filters to trigger fetching games**
        this.selectedFilters$.next(this.selectedFilters);

    });

    // Fetch filter options for display in the UI (e.g., players, maps)
    this.filters$ = this.gameService.getSearchParams().pipe(share());

    // Fetch the list of games based on selected filters
    this.games$ = this.selectedFilters$.pipe(
      startWith(this.selectedFilters),
      switchMap(filters => this.gameService.listGames(filters))
    );
  }

  updateFilter(key, value) {
    this.selectedFilters[key] = value;
    this.applyFilters(this.selectedFilters);
  }

  toggleFilter(key, value) {
    if (this.selectedFilters[key].includes(value)) {
      this.selectedFilters[key] = this.selectedFilters[key].filter(_value => _value !== value);
    } else {
      this.selectedFilters[key].push(value);
    }
    this.applyFilters(this.selectedFilters);
  }

  applyFilters(filters) {
    const queryParams = {
      status: filters.status.join(','),
      turnLength: filters.turnLength.join(','),
      minSlots: filters.minSlots,
      maxSlots: filters.maxSlots,
      isPlayer: filters.isPlayer ? 'true' : 'false',
      map_key: filters.maps.join(','), // Changed from mapId to map_key
      players: filters.players.join(','),
      objectives: filters.objectives.join(','),
      teams: filters.teams.join(','),
      reserves: filters.reserves.join(','),
      reinforcementAllowance: filters.reinforcementAllowance.join(','),
      reinforcement: filters.reinforcement.join(','),
      force: filters.force.join(','),
      privacy: filters.privacy.join(','),
      gamePlayers: filters.gamePlayers.join(',')
    };
  
    // Remove empty or null values
    Object.keys(queryParams).forEach(key => {
      if (!queryParams[key] || queryParams[key].length === 0) {
        delete queryParams[key];
      }
    });
  
    // Navigate with new query parameters and clear old ones
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams, // New query parameters
      queryParamsHandling: ''   // Ensures old parameters are not merged or preserved
    });
  
    // Trigger filtering of the game list
    this.selectedFilters$.next(filters);
  }
  
  showAdvancedSearch() {
    this.showAdvanced = true;
  }
  
  closeAdvanced() {
    this.showAdvanced = false;
  }
  
  applyAdvancedFilters(filters) {
    this.selectedFilters = Object.assign({}, this.selectedFilters, filters);
    this.applyFilters(this.selectedFilters);
  }
}