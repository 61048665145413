// home.component.ts
import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('slidesContainer') slidesContainer!: ElementRef; // Reference to the DOM element
  slideIndex: number = 0;
  totalSlides: number = 7; // Number of unique slides

  private showSlidesTimeout: any;
  private resetSlideTimeout: any;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    // Initialization logic here if needed
  }

  ngAfterViewInit(): void {
    this.showSlides();
  }

  ngOnDestroy(): void {
    this.clearTimers();
  }

  /**
   * Initiates the slideshow by setting styles and scheduling slide transitions.
   */
  showSlides(): void {
    if (!this.slidesContainer || !this.slidesContainer.nativeElement) {
      console.error('slidesContainer is not available');
      return;
    }

    const slidesContainerElement = this.slidesContainer.nativeElement as HTMLElement;

    // Apply sliding transition
    this.renderer.setStyle(slidesContainerElement, 'transition', 'transform 0.5s ease-in-out');
    this.renderer.setStyle(slidesContainerElement, 'transform', `translateX(-${this.slideIndex * 100}%)`);

    this.slideIndex++;

    // Reset to first slide seamlessly after showing the duplicate
    if (this.slideIndex > this.totalSlides) {
      this.resetSlideTimeout = setTimeout(() => {
        this.renderer.setStyle(slidesContainerElement, 'transition', 'none');
        this.renderer.setStyle(slidesContainerElement, 'transform', 'translateX(0)');
        this.slideIndex = 1; // Move to the second slide (original first)

        // Re-enable transition for future slides
        this.renderer.setStyle(slidesContainerElement, 'transition', 'transform 0.5s ease-in-out');
      }, 500); // Match this duration to the transition time
    }

    // Schedule the next slide
    this.showSlidesTimeout = setTimeout(() => this.showSlides(), 6000); // Change image every 6 seconds
  }

  /**
   * Clears all active timers to prevent showSlides from being called after component destruction.
   */
  clearTimers(): void {
    if (this.showSlidesTimeout) {
      clearTimeout(this.showSlidesTimeout);
      this.showSlidesTimeout = null;
    }
    if (this.resetSlideTimeout) {
      clearTimeout(this.resetSlideTimeout);
      this.resetSlideTimeout = null;
    }
  }
}
