import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private sharedService: SharedService,
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | boolean {
    const userData = this.sharedService.checkLoggedinUserData();

    if (userData.isUserLoggedIn) {
      return this.userService.getUserDetails(userData.userId).pipe(
        map((user) => {
          if (user.emailVerified) {
            return true;  // Allow navigation if email is verified
          } else {
            this.router.navigate(['/email-verification']);  // Redirect if email is not verified
            return false;  // Block navigation
          }
        })
      );
    } else {
      this.router.navigate(['/']);  // Redirect to home page if not logged in
      return false;
    }
  }
}
