import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GameAreaComponent } from '@game/pages/game-area/game-area.component';
import { DashboardComponent } from '@gamelist/pages/dashboard/dashboard.component';
import { GameSearchComponent } from '@gamelist/pages/game-search/game-search.component';
import { NewGameComponent } from '@gamelist/pages/new-game/new-game.component';
import { ScoreboardComponent } from '@gamelist/pages/scoreboard/scoreboard.component';
import { HomeComponent } from './modules/home/home.component';
import { EmailVerificationComponent } from './modules/home/component/email-verification/email-verification.component'; // Import your new component
import { AuthGuard } from './guard/auth.guard'; // Import AuthGuard

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'beta', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'scoreboard', component: ScoreboardComponent, canActivate: [AuthGuard] },
  { path: 'beta/new', component: NewGameComponent, canActivate: [AuthGuard] },
  { path: 'beta/search', component: GameSearchComponent, canActivate: [AuthGuard] },
  { path: 'beta/:id', component: GameAreaComponent, canActivate: [AuthGuard] },
  { path: 'email-verification', component: EmailVerificationComponent },

  // Wildcard route for handling 404 errors
  { path: '**', redirectTo: '/beta' }, // Redirect to the home page
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload', // This line ensures the component is reloaded when navigating to the same URL with different parameters.
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
