// Import finalize operator
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, interval, Subject } from 'rxjs';
import { switchMap, takeUntil, debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment'; // Import environment

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit, OnDestroy {
  currentEmail: string = '';       // User's current email address
  newEmail: string = '';           // User's new email address (optional)
  feedbackMessage: string = '';    // Message to display success or error
  emailInvalid: boolean = false;   // Track if the new email is invalid
  userId: number;                  // Store userId from SharedService
  activeTab: string = 'verify';    // Track active tab
  isLoading: boolean = false;      // Control the visibility of the loading spinner during polling
  isSending: boolean = false;      // Control the visibility of the spinner when sending emails

  private pollingSubscription: Subscription;           // For managing the polling subscription
  private componentDestroy$: Subject<void> = new Subject<void>(); // For component destruction
  private pollingDestroy$: Subject<void> = new Subject<void>();   // For polling cancellation
  public tabChangeSubject$: Subject<string> = new Subject<string>(); // For debounced tab changes
  private tabChangeSubscription: Subscription; // Subscription for tab changes
  private isPollingActive: boolean = false; // Flag to indicate if polling is active

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private userService: UserService,
    private router: Router,
    private ngZone: NgZone, // Inject NgZone
    private activatedRoute: ActivatedRoute // Inject ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Fetch the userId from SharedService
    const userData = this.sharedService.checkLoggedinUserData();
    this.userId = userData.userId;

    // Fetch the current email from the /auth/users/{userId} API
    this.getCurrentEmail(this.userId);

    // Retrieve feedback message from sessionStorage (if any)
    const storedMessage = sessionStorage.getItem('feedbackMessage');
    const storedType = sessionStorage.getItem('messageType') as 'success' | 'error' | null;
    if (storedMessage) {
      this.feedbackMessage = storedMessage;
      // Optionally, set a class based on message type
      // For example, you can bind [ngClass] in HTML to 'success' or 'error'
      // and set it here if needed
      sessionStorage.removeItem('feedbackMessage');
      sessionStorage.removeItem('messageType');
    }

    // Start polling unconditionally
    this.startPollingForVerification();

    // Subscribe to debounced tab changes
    this.tabChangeSubscription = this.tabChangeSubject$.pipe(
      debounceTime(300), // 300ms debounce time
      distinctUntilChanged() // Only emit if the current value is different than the last
    ).subscribe(tab => {
      this.handleTabChange(tab);
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from polling to avoid memory leaks
    this.stopPolling();

    // Complete the componentDestroy$ to clean up any remaining subscriptions
    this.componentDestroy$.next();
    this.componentDestroy$.complete();

    // Unsubscribe from tab changes
    if (this.tabChangeSubscription) {
      this.tabChangeSubscription.unsubscribe();
    }
  }

  /**
   * Fetches the current email of the user.
   * @param userId The ID of the user.
   */
  getCurrentEmail(userId: number): void {
    // Use backticks for string interpolation and environment variable
    const userDetailsUrl = `${environment.authApiUrl}/users/${userId}`;
    this.http.get<any>(userDetailsUrl).subscribe(
      response => {
        this.currentEmail = response.email;  // Set the fetched email
      },
      error => {
        console.error('Error fetching current email address:', error);
        this.feedbackMessage = 'Error fetching current email address.';
      }
    );
  }

  /**
   * Sends a verification email to the user.
   * If a new email is provided, it updates the email address before sending the verification link.
   * After sending, reloads the page to update the session.
   */
  sendVerificationEmail(): void {
    if (this.emailInvalid) {
      this.feedbackMessage = 'This email address is already registered.';
      return; // Exit if the email is invalid
    }

    const body = this.newEmail
      ? { currentEmail: this.currentEmail, newEmail: this.newEmail }
      : { currentEmail: this.currentEmail };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // Use environment variable for the URL
    const verifyEmailUrl = `${environment.authApiUrl}/email-verify`;

    this.isSending = true; // Start sending
    this.http.post(verifyEmailUrl, body, { headers, responseType: 'text' }).subscribe(
      response => {
        console.log('Success response:', response); // Log success response
        // Store feedback message in sessionStorage before reload
        sessionStorage.setItem('feedbackMessage', 'Please check your email for a verification link.');
        sessionStorage.setItem('messageType', 'success');
        // Reload the page to update the session with the new email
        window.location.reload();
      },
      error => {
        console.error('Error sending verification email:', error); // Log error response
        let message = 'An error occurred. Please refresh and try again.';
        let type: 'success' | 'error' = 'error';
        if (error.status === 400) {
          message = 'Bad Request: Please check your input.';
        } else if (error.status === 401) {
          message = 'Unauthorized: Please log in again.';
        } else if (error.status === 500) {
          message = 'Server Error: Please try again later.';
        }
        // Store the error message and type in sessionStorage before reload
        sessionStorage.setItem('feedbackMessage', message);
        sessionStorage.setItem('messageType', type);
        // Reload the page to display the error message
        window.location.reload();
      }
    );
  }

  /**
   * Initiates polling to check if the user's email has been verified.
   * Ensures that only one polling subscription is active at any given time.
   */
  startPollingForVerification(): void {
    if (this.isPollingActive) {
      // Polling is already active; do not start another
      return;
    }

    this.isPollingActive = true;

    // Initialize a new pollingDestroy$ Subject for the new polling subscription
    this.pollingDestroy$ = new Subject<void>();

    // Set the loading state to true within Angular's zone to trigger change detection
    this.ngZone.run(() => {
      this.isLoading = true;
    });

    // Start polling outside Angular's zone to prevent unnecessary change detection cycles
    this.ngZone.runOutsideAngular(() => {
      this.pollingSubscription = interval(5000) // Poll every 5 seconds
        .pipe(
          switchMap(() => this.userService.getUserDetails(this.userId)),
          takeUntil(this.pollingDestroy$), // Stop polling when pollingDestroy$ emits
          finalize(() => {
            // Ensure isLoading is set to false when polling stops
            this.ngZone.run(() => {
              this.isLoading = false;
              this.isPollingActive = false;
            });
          })
        )
        .subscribe(
          response => {
            if (response.emailVerified) {
              // Re-enter Angular's zone to update UI and perform navigation
              this.ngZone.run(() => {
                this.feedbackMessage = 'Email has been successfully verified!';
                console.log('Email verified, redirecting to /beta');
                this.isLoading = false; // Hide the loading spinner
                this.isPollingActive = false;
                this.router.navigate(['/beta']); // Redirect to /beta once verified
              });
              this.stopPolling(); // Stop polling after verification
            }
          },
          error => {
            console.error('Error checking email verification status:', error);
            // Handle polling errors by updating the feedback message and hiding the spinner
            this.ngZone.run(() => {
              this.feedbackMessage = 'Error checking verification status. Please try again later.';
              this.isLoading = false; // Hide the loading spinner
              this.isPollingActive = false;
            });
            this.stopPolling(); // Optionally stop polling on error
          }
        );
    });
  }

  /**
   * Stops the polling subscription and cleans up resources.
   * Also updates the loading state to hide the spinner.
   */
  stopPolling(): void {
    // Unsubscribe from the polling subscription if it's active
    if (this.pollingSubscription && !this.pollingSubscription.closed) {
      this.pollingSubscription.unsubscribe();
    }

    // Emit and complete the pollingDestroy$ Subject to stop polling
    if (this.pollingDestroy$) {
      this.pollingDestroy$.next();
      this.pollingDestroy$.complete();
      this.pollingDestroy$ = null; // Reset to prevent multiple completions
    }

    // Update the loading state within Angular's zone to hide the spinner
    this.ngZone.run(() => {
      this.isLoading = false;
      this.isPollingActive = false;
    });
  }

  /**
   * Validates if the new email provided by the user is already registered.
   * Updates the UI based on the validation result.
   */
  checkValidEmail(): void {
    this.emailInvalid = false;
    if (this.newEmail.trim() !== '') {
      const fieldData: any = { 'functionName': 'emailAlreadyRegistered', 'email': this.newEmail };

      this.userService.initSignup(fieldData).subscribe(
        response => {
          if (!response.success) {
            this.emailInvalid = true; // Set emailInvalid to true if the email is already registered
            this.feedbackMessage = 'This email is already registered. Please use another email.';
          } else {
            this.feedbackMessage = ''; // Clear the error message when the email is valid
          }
          // No need to manually trigger change detection as updates occur within Angular's zone
        },
        error => {
          console.error('Error checking email validity:', error);
          this.feedbackMessage = 'Error validating email. Please try again later.';
        }
      );
    } else {
      this.feedbackMessage = ''; // Reset feedbackMessage if the newEmail field is cleared
    }
  }

  /**
   * Handles tab switching between 'verify email' and 'change email'.
   * Since polling runs continuously, no need to start/stop polling based on tab.
   * @param tab The tab being switched to.
   */
  handleTabChange(tab: string): void {
    if (tab === this.activeTab) {
      // No change in tab; do nothing
      return;
    }

    this.activeTab = tab; // Update the active tab

    // Optional: Provide feedback to the user about the active tab
    // For example, you might want to reset feedback messages or update UI elements
  }
}
