import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Login, SignUp } from '../interfaces/login';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  data = {};

    initLogin(loginData: Login): any {
        if (loginData) {
          let bAuthHeaderStr = 'Basic ' + btoa(`${loginData.username}:${loginData.password}`);
          let opt = {
            withCredentials: true,
            headers: {
              'Authorization': bAuthHeaderStr
            }
          };
      
          return this.http.post(
            `${environment.apiBaseUrl}/rest-api/restApiActions.php`,
            loginData,
            opt
          );
        }
      }
      
    initLogout(): any {
    let bAuthHeaderStr = 'Basic cmFkaWFuc3lzOnJhZGlhbnN5cw==';

    let opt = {
        withCredentials: true,
        headers: {
            'Authorization': bAuthHeaderStr
      }
    };

        return this.http.post(
            `${environment.apiBaseUrl}/rest-api/restApiActions.php`,
        { 'functionName': 'logout' }, opt
    );
    }

    initSignup(signupData: SignUp): any {
      if (signupData) {
        return this.http.post(
            `${environment.apiBaseUrl}/rest-api/restApiActions.php`,
            signupData
        );
      }
    }

    getBlacklistedWords(): any {
        return this.http.post(
            `${environment.apiBaseUrl}/rest-api/restApiActions.php`,
            { 'functionName': 'getBlacklistedWords' }
        );
  }
      
    // Add method to get user details, including email verification status
    getUserDetails(userId: number): Observable<any> {
      return this.http.get(`${environment.authApiUrl}/users/${userId}`);
    }
}
